// Global styling for this template

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

#wrapper {
  display: flex;
  #content-wrapper {
    overflow-x: hidden;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: $sticky-footer-height;
    background: #eee;
  }
}
// Fixed Nav Option
body.fixed-nav {
  #content-wrapper {
    margin-top: $navbar-base-height;
    padding-left: $sidebar-collapsed-width;
  }
  &.sidebar-toggled {
    #content-wrapper {
      padding-left: 0;
    }
  }
  @media(min-width: 768px) {
    #content-wrapper {
      padding-left: $sidebar-base-width;
    }
    &.sidebar-toggled {
      #content-wrapper {
        padding-left: $sidebar-collapsed-width;
      }
    }
  }
}
