.bg-yellow {
  background: $yellow;
}

.bg-blue {
  background: $blue2;
}

.bg-navy-blue {
  background: $navy-blue;
}
