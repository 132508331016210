@import "../scss/sb-admin";

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-color: #656565;
}

.logout {
  position: absolute;
  right: 20px;
}

input:required {
  border-right-color: palegreen;
  border-right-width: 4px;
}

input:invalid {
  border-right-color: salmon;
  border-right-width: 4px;
}
